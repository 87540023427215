<template>
  <div>
    <v-container v-if="loading" class="text-center">
      <v-progress-circular color="primary" indeterminate></v-progress-circular>
    </v-container>
    <v-card
      v-else-if="
        series.length === 0 ||
        ('data' in series[0] && series[0]['data'].length === 0)
      "
    >
      <v-card-text>{{ noDataText }}</v-card-text>
    </v-card>
    <div v-else>
      <v-card v-for="(seriesItem, index) in series" :key="index" class="mt-4">
        <v-card-title> {{ seriesItem.name }} </v-card-title>
        <vue-apex-charts
          type="line"
          :height="height"
          :options="chartOptions"
          :series="seriesItem"
        ></vue-apex-charts>
      </v-card>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { noDataText } from "@/utils/constants";
import axios, { cacheTime } from "@axios";
import debounce from "lodash/debounce";
import { colors, chartColors } from "@/utils/constants";
import { getDaysArray, monthRange } from "@/utils/dates";
import { mdiAlert, mdiEye } from "@mdi/js";
import { formatNumber } from "@/utils/formatting";
import { ONE_DAY_MICROTIME } from "@/utils/dates";

const TODAY = new Date();
const YESTERDAY = new Date(TODAY.getTime() - ONE_DAY_MICROTIME);

const yesterdayStr = [
  YESTERDAY.getFullYear(),
  String(YESTERDAY.getMonth() + 1).padStart(2, "0"),
  String(YESTERDAY.getDate()).padStart(2, "0"),
].join("-");

export default {
  name: "CaByPartnerViewSeparatedChart",
  components: {
    VueApexCharts,
  },
  props: {
    partnersConfigs: {
      type: Array,
      default: () => [],
    },
    sites: {
      type: Array,
      default: () => [],
    },
    month: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      icons: {
        mdiAlert,
        mdiEye,
      },
      noDataText: null,
      loading: false,
      title: "",
      legend: true,
      series: [{ name: "", data: [] }],
      colors: [colors.ca],
      stroke: {
        show: true,
        curve: "smooth",
        width: 2,
        dashArray: [], // 0, 2
      },
      height: 300,
      labelsEnabled: false,
      yAxis: [
        {
          title: {
            text: "Revenue",
          },
          seriesName: "Revenue",
          labels: {
            formatter: (val) => formatNumber(val),
          },
          min: 0,
          forceNiceScale: true,
          labels: {
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
        },
      ],
      xAxis: {
        categories: [],
        labels: {
          rotate: -45,
          rotateAlways: true,
        },
        style: {
          fontSize: "12px",
          fontWeight: 400,
        },
      },
      chartOptions: {
        chart: {
          type: "line",
          zoom: {
            enabled: false,
          },
        },
        markers: {
          size: 0,
          hover: {
            sizeOffset: 6,
          },
        },
        grid: {
          borderColor: "#f1f1f1",
        },
      },
    };
  },
  created() {
    this.debouncedGetData();
  },
  methods: {
    debouncedGetData: debounce(async function () {
      await this.getData();
    }, 500),
    async getData() {
      try {
        // const range = monthRange(this.month);
        // const categories = getDaysArray(range[0], range[1]) || []; // Ensure categories is an array
        const categories = Array.from({ length: 31 }, (_, i) => {
          return `${this.month}-${String(i + 1).padStart(2, "0")}`;
        });

        let queryParams = {
          group_ca: false,
          month: this.month,
          ...(this.sites && this.sites.length > 0
            ? { sites: this.sites.join(",") }
            : {}),
          ...(this.partnersConfigs && this.partnersConfigs.length > 0
            ? { partnersconfigs: this.partnersConfigs.join(",") }
            : {}),
          minus_one_type: "minus_one_month", // will return data of previous month also
        };

        const response = await axios.get(
          "/programmatic/detailed-revenue-monthly-report",
          {
            params: queryParams,
            cache: {
              maxAge: cacheTime,
            },
          }
        );

        if (!response.data || response.data.length === 0) {
          console.warn("No data available from the response");
          this.noDataText = "No data available";
          this.loading = false;
          return;
        }

        const uniquePartnerViews = [
          ...new Set(
            response.data.current.map((item) => item.partner_view_label)
          ),
        ];

        this.colors = chartColors.slice(0, uniquePartnerViews.length);
        const caByPartnerViewAndDayDataCurrent = response.data.current.reduce(
          (acc, { partner_view_label, date, ca }) => {
            if (!acc[partner_view_label]) acc[partner_view_label] = {};
            acc[partner_view_label][date] = Math.floor(ca); // formatNumber(ca);
            return acc;
          },
          {}
        );
        const caByPartnerViewAndDayDataPrevious = response.data.previous.reduce(
          (acc, { partner_view_label, date, ca }) => {
            if (!acc[partner_view_label]) acc[partner_view_label] = {};
            const [year, month, day] = date.split("-");
            acc[partner_view_label][`${this.month}-${day}`] = Math.floor(ca); // formatNumber(ca);
            return acc;
          },
          {}
        );
        const series = [];
        for (const partnerViewName of uniquePartnerViews) {
          let serieDataCurrent = [];
          for (const dayStr of categories) {
            serieDataCurrent.push(
              caByPartnerViewAndDayDataCurrent?.[partnerViewName]?.[dayStr] ||
                null
            );
          }
          let serieDataPrevious = [];
          for (const dayStr of categories) {
            serieDataPrevious.push(
              caByPartnerViewAndDayDataPrevious?.[partnerViewName]?.[dayStr] ||
                null
            );
          }
          series.push([
            {
              name: partnerViewName,
              data: serieDataCurrent,
            },
            {
              name: "M -1",
              data: serieDataPrevious,
            },
          ]);
        }
        // console.log("series", series);
        if (!series.length || !series[0][0].data.length) {
          console.warn("No series data available");
          this.noDataText = "No data available";
          this.loading = false;
          return;
        }

        const annotations = {
          xaxis: [
            {
              x: yesterdayStr,
              borderColor: "#FF0000",
              fillColor: "#FF0000",
              opacity: 0.6,
              label: {
                // borderColor: "#333",
                // style: {
                //   fontSize: "10px",
                //   color: "#333",
                //   background: "#FEB019",
                //   minWidth: "200px",
                //   minHeight: "80px",
                // },
                text: "",
              },
            },
          ],
        };

        this.chartOptions = {
          ...this.chartOptions,
          chart: {
            ...this.chartOptions.chart,
            id: this.title,
            height: this.height,
          },
          annotations: annotations,
          legend: {
            show: this.legend,
          },
          colors: this.colors,
          dataLabels: {
            enabled: this.labelsEnabled,
          },
          //stroke: this.stroke,
          stroke: {
            width: [2, 1],
            show: true,
            curve: "smooth", //"straight",
            dashArray: [0, 2],
          },
          xaxis: {
            categories: categories,
            labels: {
              rotate: -45,
              rotateAlways: true,
            },
            style: {
              fontSize: "12px",
              fontWeight: 400,
            },
          },
          yaxis: this.yAxis,
        };

        // this.series = [
        //   [{ name: "GAM", data: [5, 15, 18, 56, 23, 42] }, { name: "GAM M -1", data: [3, 11, 15, 36, 37, 48] }],
        //   [{ name: "Seedtag", data: [12, 9, 11, 45, 33, 78] }, { name: "Seedtag M -1", data: [13, 5, 6, 26, 29, 42] }],
        // ];

        // calculate total of data array
        // const calculateTotal = (item) => item.data.reduce((sum, value) => sum + value, 0);
        // let sortedSeries = series.sort( (a, b) => calculateTotal(b) - calculateTotal(a) );

        let sortedSeries = series.sort((a, b) => {
          const sumA = a[0].data.reduce((acc, curr) => acc + curr, 0);
          const sumB = b[0].data.reduce((acc, curr) => acc + curr, 0);
          return sumB - sumA; // Sort descending
        });

        // if (true) {
        //   // pendant les tests
        //   sortedSeries = sortedSeries.slice(0, 1);
        // }
        this.series = sortedSeries;

        this.loading = false;
      } catch (error) {
        console.error("Error loading chart data:", error);
        this.noDataText = "Error loading data";
        this.loading = false;
      }
    },
  },

  watch: {
    month: function () {
      if (this.month) this.debouncedGetData();
    },
    partnersConfigs: function () {
      this.debouncedGetData();
    },
    sites: function () {
      this.debouncedGetData();
    },
  },
};
</script>
